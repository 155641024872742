<template>
  <div class="Popup_Inner" :class="customClass">
    <div class="Popup_Inner_main">
      <div @click="close" class="Popup_close_btn">
        <img
          @load="imgLoaded"
          class="imgHide"
          src="@/assets/media/vectors/close.svg"
          alt=""
        />
      </div>
      <div style="width: 100%;">
        <div class="Popup_title" style="margin-top: 0.5rem;">
          {{ title }}
        </div>
        <div class="Popup_subTitle">
          {{ subtitle }}
        </div>
        <div v-if="content" v-html="content"></div>
        <div v-if="inputs.length">
          <div
            v-for="(e, i) in inputs"
            :key="i"
            class="form-group"
            :class="{ WRNG: inputsInvalids[e.id] }"
          >
            <b v-if="e.type != 'files' && e.label">{{ e.label }}</b>
            <div class="mx-full_width">
              <input
                v-if="!e.type"
                type="text"
                class="vInput"
                :class="{ 'form-disabled': e.disabled }"
                :disabled="e.disabled"
                v-model="inputsModels[e.id]"
              />
              <input
                v-else-if="e.type == 'number'"
                type="number"
                :min="0"
                class="vInput"
                :class="{ 'form-disabled': e.disabled }"
                :disabled="e.disabled"
                v-model="inputsModels[e.id]"
              />
              <div v-else-if="e.type == 'checkbox'">
                <input
                  :id="e.id"
                  type="checkbox"
                  :class="{ 'form-disabled': e.disabled }"
                  :disabled="e.disabled"
                  v-model="inputsModels[e.id]"
                />
                <label :for="e.id"> {{ e.name }} </label><br />
              </div>
              <textarea
                v-else-if="e.type == 'textarea'"
                v-model="inputsModels[e.id]"
                class="vInput"
                width="100%"
                cols=""
                rows="10"
              ></textarea>
              <editor
                v-else-if="e.type == 'editor'"
                v-model="inputsModels[e.id]"
                minHeight="150"
                maxHeight="230"
              />
              <date-picker
                v-else-if="e.type == 'date'"
                v-model="inputsModels[e.id]"
                :type="e.dateType || 'date'"
                :format="e.format || 'DD-MM-YYYY'"
                :valueType="e.valueType || 'YYYY-MM-DDTHH:mm:ssZ'"
                :disabled-date="e.valueDisable || (() => false)"
                popup-class="bigger-index"
                :disabled="e.disabled || false"
              />
              <v-select
                v-else-if="e.type == 'select'"
                v-model="inputsModels[e.id]"
                :label="e.selectLabel || 'label'"
                :options="e.selectData || []"
                :loading="!!e.loading"
                :searchable="!e.disableSearchable"
              >
                <template #option="item">
                  <span class="v-select_li_custom">
                    <span>{{ item[e.selectLabel || "label"] }}</span>
                    <i
                      v-tooltip="item[e.selectLabel || 'label']"
                      class="fas fa-info-circle"
                    ></i>
                  </span>
                </template>
              </v-select>
              <v-select-infinite
                v-else-if="e.type == 'infiniteSelect'"
                v-model="inputsModels[e.id]"
                :apiOptions="e.apiOptions || {}"
                :label="e.selectLabel || 'label'"
                :apiModule="e.apiModule || false"
                :searchable="!e.disableSearchable"
              >
                <template #option="item">
                  <span class="v-select_li_custom">
                    <span>{{ item[e.selectLabel || "label"] }}</span>
                    <i
                      v-tooltip="item[e.selectLabel || 'label']"
                      class="fas fa-info-circle"
                    ></i>
                  </span>
                </template>
              </v-select-infinite>
              <component
                v-else-if="e.type == 'files'"
                :is="'files-agent'"
                :label="e.label"
                v-model="inputsModels[e.id]"
              />
              <component
                v-else-if="e.type == 'multy-inputs'"
                :is="'multy-inputs'"
                :label="e.label"
                :singleSelectMode="true"
                v-model="inputsModels[e.id]"
                v-bind="e.componentProps || {}"
              />
              <component
                v-else-if="e.type == 'component'"
                :is="e.component"
                v-model="inputsModels[e.id]"
                v-bind="{ ...e.componentProps, parentTarget: target } || {}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="Popup_footer"
      style="display: flex;justify-content: space-between;"
    >
      <div class="Popup_footer_col">
        <button @click="close" class="Popup_footer_btn Footer_btn1">
          {{ closeBtnText }}
        </button>
      </div>
      <div v-if="secondaryButton" class="Popup_footer_col">
        <button
          @click="secondaryCallHandler"
          class="Popup_footer_btn"
          :class="button.type ? `Footer_btn${secondaryButton.type}` : ''"
        >
          {{ secondaryButton.value }}
        </button>
      </div>
      <div class="Popup_footer_col">
        <button
          v-if="!button.disabled"
          @click="callHandler"
          class="Popup_footer_btn"
          :class="button.type ? `Footer_btn${button.type}` : ''"
        >
          {{ button.value }}
        </button>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/DialogModal.css"></style>

<script>
import DatePicker from "@/mixins/DatePicker.js";
const isFn = (fn) => typeof fn == "function";

export default {
  props: {
    target: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    inputs: {
      type: Array,
      default: () => [],
    },
    idFromObjMap: {
      type: Array,
      default: () => [],
    },
    prepareModelsFunctions: {
      type: Object,
      default: () => ({}),
    },
    acceptFinish: {
      type: Function,
      default: () => true,
    },
    button: {
      type: Object,
      default: () => ({ disabled: true }),
    },
    initialInputsData: {
      type: Object,
      default: null,
    },
    initialInputsReason: {
      type: String,
      default: "Datele nu au fost modificate.",
    },
    closeBtnText: {
      type: String,
      default: "Înapoi",
    },
    secondaryButton: {
      type: Object,
      default: null,
    },
    valueUpdated: {
      type: Function,
      default: () => {},
    },

    customClass: {
      type: String,
      default: "",
    },
  },
  mixins: [DatePicker],
  components: {
    "files-agent": () => import("@/components/FilesAgent"),
    "multy-inputs": () => import("@/components/MultyInputsFields"),
  },
  data: () => ({
    inputsModels: {},
    inputsInvalids: {},
  }),
  computed: {
    previousInputsModels() {
      return Object.assign({}, this.inputsModels);
    },
  },
  watch: {
    previousInputsModels: {
      handler(x, prev) {
        Object.entries(x).forEach((e) => {
          if (e[1] != prev[e[0]]) {
            this.inputsInvalids[e[0]] = false;
          }
        });
      },
      deep: true,
    },
    inputsModels: {
      handler(x) {
        const y = this.inputs;
        for (let key in x) {
          const z = y.find((e) => e.id === key);

          const w = z?.reactiveValue;
          if (this.isObject(w)) {
            const findInput = x[w.internalInputId];

            if (findInput !== undefined) {
              let prepareValue = findInput;
              if (Array.isArray(w.labelPath)) {
                prepareValue = this.walker(prepareValue, w.labelPath);
              }

              x[key] = prepareValue || "";
            }
          }
        }

        this.valueUpdated(x);
      },
      deep: true,
    },
  },
  methods: {
    syncInputs() {
      const x = {};
      const y = {};
      const z = this.initialInputsData || {};
      const prepareTypesMap = {
        date: (x) => String(x),
      };

      this.inputs.forEach((e) => {
        const setVal = (val) => (x[e.id] = val);

        const valuesMap = {
          files: [],
        };

        if (e.value) {
          setVal(e.value);
        } else {
          let initial = z[e.id];
          if (initial) {
            setVal(
              prepareTypesMap[e.type]
                ? prepareTypesMap[e.type](initial)
                : initial
            );
          } else {
            setVal(valuesMap[e.type] || "");
          }
        }

        y[e.id] = false;
      });

      this.inputsModels = x;
      this.inputsInvalids = y;
    },
    close() {
      this.$modal.hide(this.target);
      if (isFn(this.closeBtnEvent)) {
        this.closeBtnEvent();
      }
    },
    getPreparedInputsModels() {
      const sendData = { ...this.inputsModels };
      for (let key of this.idFromObjMap) {
        const x = sendData[key];
        if (x) {
          sendData[key] = x?.id ?? null;
        }
      }

      for (let e of Object.entries(this.prepareModelsFunctions)) {
        const x = sendData[e[0]];

        if (typeof e[1] == "function") {
          sendData[e[0]] = e[1](x);
        }
      }

      return sendData;
    },
    secondaryCallHandler() {
      if (isFn(this.secondaryButton.handler)) {
        this.$modal.hide(this.target);
        this.secondaryButton.handler(this.getPreparedInputsModels());
      }
    },
    async callHandler() {
      const sendData = this.getPreparedInputsModels();
      if (this.checkValids() || !(await this.acceptFinish(sendData))) return;

      if (isFn(this.button.handler)) {
        this.$modal.hide(this.target);
        this.button.handler(sendData);
      }
    },
    checkValids() {
      const defaultChecker = (val, model = {}) => {
        if (val === null) return;
        const str = String(val);
        const trim = str.trim();

        if (!str) return false;
        if (!model.type && !trim) return false;

        return true;
      };
      const x = this.initialInputsData;

      const models = this.getPreparedInputsModels();

      let err = false;
      let msg = "";
      let isEdited = false;
      let disableAlert = false;

      for (let e of this.inputs) {
        const val = models[e.id] || null;
        const isChecker = isFn(e.checker);

        if (isChecker ? !e.checker(val) : !defaultChecker(val, e)) {
          err = true;
          this.inputsInvalids[e.id] = true;
          if (isChecker && e.disableAlert) {
            disableAlert = true;
          } else if (!disableAlert) {
            msg =
              msg ||
              e.errReason ||
              `Valoarea "${e.label || e.id}" este greșită.`;
          }
        } else {
          if (x && this.isEdited({ a: x[e.id] }, { a: val })) {
            isEdited = true;
          }
        }
      }

      if (!disableAlert) {
        if (msg) {
          this.$toastr.w(msg);
        } else if (x && !isEdited) {
          this.$toastr.w(this.initialInputsReason);
          return true;
        }
      }
      return err;
    },
  },

  created() {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    this.syncInputs();
  },

  destroyed() {
    document.getElementsByTagName("html")[0].style.overflow = "";
  },
};
</script>
